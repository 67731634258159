/* eslint-disable */
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "@/core/services/store";
import ApiService from "@/core/services/api.service";
import { RESET_LAYOUT_CONFIG } from "@/core/services/store/config.module";
import { VERIFY_AUTH } from "@/core/services/store/auth.module";
import { LOGIN } from "@/core/services/store/auth.module";
import VueGoodTablePlugin from 'vue-good-table';
import vSelect from 'vue-select'
import 'vue-good-table/dist/vue-good-table.css';
import Vuelidate from 'vuelidate'
import * as VueGoogleMaps from 'vue2-google-maps'
import VueSession from 'vue-session'
//import VueI18n from 'vue-i18n'
import i18n from './i18n'
import  Datetime  from 'vue-datetime'
import 'vue-datetime/dist/vue-datetime.css'
import VueMeta from 'vue-meta'
 
Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
})

//Vue.use(VueI18n)
Vue.use(VueGoodTablePlugin);
Vue.component('vue-select', vSelect)
Vue.use(Vuelidate)
Vue.use(VueGoogleMaps,{
  load: {
    //key: 'AIzaSyDf43lPdwlF98RCBsJOFNKOkoEjkwxb5Sc',
    key:'AIzaSyCYiD6NQ1pH2_-hCNDaxgxR_7E8sEQGtSo',
    libraries: "places,drawing" // necessary for places input
  }
})
var options = {
  persist: true
}
Vue.use(VueSession,options)
Vue.use(Datetime)



Vue.config.productionTip = false;

// Global 3rd party plugins
import "popper.js";
import "tooltip.js";
import PerfectScrollbar from "perfect-scrollbar";
window.PerfectScrollbar = PerfectScrollbar;
import ClipboardJS from "clipboard";
window.ClipboardJS = ClipboardJS;

// Vue 3rd party plugins
//import i18n from "@/core/plugins/vue-i18n";
import vuetify from "@/core/plugins/vuetify";
import "@/core/plugins/google-maps";
import "@/core/plugins/portal-vue";
import "@/core/plugins/bootstrap-vue";
import "@/core/plugins/perfect-scrollbar";
import "@/core/plugins/highlight-js";
import "@/core/plugins/inline-svg";
import "@/core/plugins/apexcharts";
import "@/core/plugins/treeselect";
import "@/core/plugins/metronic";
import "@mdi/font/css/materialdesignicons.css";
import "@/core/plugins/formvalidation";
import 'vue-select/dist/vue-select.css';


// API service init
ApiService.init();
window.store = store
window.ApiService = ApiService

// Remove this to disable mock API
// MockService.init();

router.beforeEach(async (to, from, next) => {
  // Ensure we checked auth before each page load.  
  // Promise.all([store.dispatch(VERIFY_AUTH)]).then(next);

  // reset config to initial state
  store.dispatch(RESET_LAYOUT_CONFIG);

  let currentUser = null;
  try {
    currentUser = await store.dispatch(VERIFY_AUTH)
    
   
  } catch (e) {
    
  }

  if (to.meta.requiresAuth) {
    //console.log(store.state.auth.cred)
    /*var x = 'TREDS'
    var crypt = 'RTaB8-9541X'+x+'Q75CTAZ53X'
    console.log(crypt.split('RTaB8-9541X')[1].split('Q75CTAZ53X')[0])*/
    if(localStorage.getItem('email')!==null && localStorage.getItem('pass') !== null){

      var credintals = {
        email:localStorage.getItem('email'),
        password:localStorage.getItem('pass').split('RTaB8-9541X')[1].split('Q75CTAZ53X')[0]
      }

    }
   
    //console.log(credintals)
    // Verify the user is still authenticated
    if (to.name !== 'login' && !currentUser) {
      //localStorage.clear()
      //return next({ name: 'login' })
      //console.log("NEED AUTaH")
  if(localStorage.getItem('email')!==null && localStorage.getItem('pass') !== null){

    await store.dispatch(LOGIN,credintals).then(()=>{
      
    }).catch(err=>{
      return next({ name: 'login' })
    })
    return next()
      
    }else{
      return next({ name: 'login' })
    }
       
      

      
       
    }
    else 
      return next()
  } else {
    return next();
  }

  next()


  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount("#app");
