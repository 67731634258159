/* eslint-disable no-unused-vars */
import store from "@/core/services/store";
import ApiService from "@/core/services/api.service";

export default {
    namespaced: true,
    state: {
        corders: [
            {id:1 , job_id:'55' , area:'area1' , dname:'Ahmad Mohammad' , fees:252},
            {id:2 , job_id:'10' , area:'area2' , dname:'Hikmat Malkawi' , fees:100},
            {id:3 , job_id:'8' , area:'area14' , dname:'Sami Ahmad' , fees:122},
            {id:4 , job_id:'6' , area:'area3' , dname:'John Whick' , fees:324},     
        ],
    },
    mutations: {
        SET_CORDERS(state, corders) {
            state.corders = corders;
        },
        ADD_CORDERS(state, corder) {
            let corders = state.corders.concat(corder);
            state.corders = corders;
        },
        DELETE_CORDERS(state, corderId) {
            let corders = state.corders.filter(d => d.id != corderId);
            state.corders = corders;
        },
        UPDATE_CORDERS(state, corder) {
            let updatedcorder = state.corders.find(d => d.id == corder.id);
            updatedcorder = corder;
        },
    },
    actions: {
        async loadAll({ commit }) {
            let response = await ApiService.get('api/v1/drivers');
            let drivers = response.data.data;            
            commit('SET_CORDERS', drivers);
        },
        async create({ commit }, driver) {
            let response = await ApiService.post('api/v1/drivers', driver);
            let savedDriver = response.data;
            commit('ADD_CORDERS', savedDriver);
            return savedDriver;
        },
        async delete({ commit }, driver) {
            let response = await ApiService.delete(`api/v1/drivers/${driver.id}`);
            if (response.status == 200 || response.status == 204) {
                commit('DELETE_CORDERS', driver.id);
            }
        },
        async update({ commit }, driver) {
            let response = await ApiService.update("api/v1/drivers", driver.id, driver);
            let newDriver = response.data;
            commit('UPDATE_CORDERS', newDriver);
            return newDriver;
        },
    },
    getters: {
        table: (state) => {
            return {
                columns: [
                    {
                      label: "ID",
                      field: "id",
                    },
                    {
                      label: "Job Id",
                      field: "job_id",
                    },
                    {
                      label: "Area",
                      field: "area",
                      
                    },
                    {
                      label: "Driver Name",
                      field: "dname",
                      
                    },
                    {
                        label: "Fees",
                        field: "fees",
                        
                      },
                    {
                      label: "Actions",
                      field: "actions",
                      type: "html",
                      thClass:'text-center',
                      tdClass:'text-center'
                    },
                  ],

                rows: state['corders'],
            }
        },
        
    },
}