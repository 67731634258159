/* eslint-disable no-unused-vars */
import store from "@/core/services/store";
import ApiService from "@/core/services/api.service";

export default {
    namespaced: true,
    state: {
        drivers: [],
    },
    mutations: {
        SET_DRIVERS(state, drivers) {
            state.drivers = drivers;
        },
        ADD_DRIVER(state, driver) {
            let drivers = state.drivers.concat(driver);
            state.drivers = drivers;
        },
        DELETE_DRIVER(state, driverId) {
            let drivers = state.drivers.filter(d => d.id != driverId);
            state.drivers = drivers;
        },
        UPDATE_DRIVER(state, driver) {
            let updatedDriver = state.drivers.find(d => d.id == driver.id);
            updatedDriver = driver;
        },
        LOAD_PAGES(state, data){
            if(state.drivers.findIndex(x=>x.id === data[0].id)===-1){
                state.drivers = state.drivers.concat(data)
            }
        }
    },
    actions: {
        async loadAll({ commit }) {
            let response = await ApiService.get('api/v1/drivers');
            let drivers = response.data.data;            
            commit('SET_DRIVERS', drivers);
            return response;
        },
        async search({ commit },value) {
            let response = await ApiService.get('api/v1',`drivers?filter=${value}`);
            let drivers = response.data.data;            
            commit('SET_DRIVERS', drivers);
            return response;
        },
        async loadPage({ commit},pageNumber ) {
            let response = await ApiService.get(`api/v1`,`drivers?page=${pageNumber}`);
            var data = response.data.data
            commit('LOAD_PAGES', data);
            
            return response
        },
        async create({ commit }, driver) {
            let response = await ApiService.post('api/v1/drivers', driver);
            let savedDriver = response.data;
            commit('ADD_DRIVER', savedDriver);
            return savedDriver;
        },
        async delete({ commit }, driver) {
            let response = await ApiService.delete(`api/v1/drivers/${driver.id}`);
            if (response.status == 200 || response.status == 204) {
                commit('DELETE_DRIVER', driver.id);
            }
        },
        async update({ commit }, driver) {
            let response = await ApiService.update("api/v1/drivers", driver.id, driver);
            let newDriver = response.data;
            commit('UPDATE_DRIVER', newDriver);
            return newDriver;
        },
        async Transactions({commit},driver){
            let response = await ApiService.get(`api/v1/driver/transactions`,driver.id)
            return response
        },
        async CreateTransaction ({commit},transaction){
            let response = await ApiService.post('api/v1/driver/transactions',transaction)
            return response
        }
    },
    getters: {
        table: (state) => {
            return {
                fields: [
                    {
                        key: "id",
                        label: "ID",
                        sortable: true,
                        sortDirection: "desc",
                      },
                      {
                        key: "full_name",
                        label: "Full Name",
                        sortable: true,
                        sortDirection: "desc",
                      },
                      {
                        key: "phone",
                        label: "Phone",
                        sortable: true,
                        sortDirection: "desc",
                      },
                      {
                        key: "national",
                        label: "National ID",
                        sortable: true,
                        sortDirection: "desc",
                      },
                      
                      { key: "actions", label: "Actions" },
                ],

                items: state['drivers'],
            }
        },
        tableAR: (state) => {
            return {
                fields: [
                    {
                        key: "id",
                        label: "المعرف",
                        sortable: true,
                        sortDirection: "desc",
                      },
                      {
                        key: "full_name",
                        label: "الإسم الكامل",
                        sortable: true,
                        sortDirection: "desc",
                      },
                      {
                        key: "phone",
                        label: "رقم الجوال",
                        sortable: true,
                        sortDirection: "desc",
                      },
                      {
                        key: "national",
                        label: "الرقم الوطني",
                        sortable: true,
                        sortDirection: "desc",
                      },
                      
                      { key: "actions", label: "إعدادات" },
                ],

                items: state['drivers'],
            }
        }
    },
}