import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [
    {
      path: "/admin",
      redirect: "/admin/dashboard",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "/admin/dashboard",
          name: "dashboard",
          component: () => import("@/view/pages/Dashboard.vue"),
          meta: { requiresAuth: true },
        },
        {
          path: "/admin/queues",
          name: "Queues",
          component: () => import("@/view/pages/Queues/Queues.vue"),
          meta: { requiresAuth: true },
        },
        {
          path: "/admin/queues/create",
          name: "CreateQueue",
          component: () => import("@/view/pages/Queues/CreateQueue.vue"),
          meta: { requiresAuth: true },
        },
        {
          path: "/admin/queues/:id",
          name: "ViewQueue",
          component: () => import("@/view/pages/Queues/ViewQueue.vue"),
          meta: { requiresAuth: true },
        },
        {
          path: "/admin/drivers",
          name: "Drivers",
          component: () => import("@/view/pages/Drivers/Drivers.vue"),
          meta: { requiresAuth: true },
        },
        {
          path: "/admin/drivers/create",
          name: "CreateDriver",
          component: () => import("@/view/pages/Drivers/CreateDriver.vue"),
          meta: { requiresAuth: true },
        },
        {
          path: "/admin/drivers/:id",
          name: "ViewDriver",
          component: () => import("@/view/pages/Drivers/ViewDriver.vue"),
          meta: { requiresAuth: true },
        },
        {
          path: "/admin/clients",
          name: "Clients",
          component: () => import("@/view/pages/Clients/Clients.vue"),
          meta: { requiresAuth: true },
        },
        {
          path: "/admin/clients/create",
          name: "CreateClient",
          component: () => import("@/view/pages/Clients/CreateClient.vue"),
          meta: { requiresAuth: true },
        },
        {
          path: "/admin/clients/:id",
          name: "ViewClient",
          component: () => import("@/view/pages/Clients/ViewClient.vue"),
          meta: { requiresAuth: true },
        },
        {
          path: "/admin/orders",
          name: "Orders",
          component: () => import("@/view/pages/Orders/Orders.vue"),
          meta: { requiresAuth: true },
        },
        {
          path: "/admin/orders/:id",
          name: "ViewOrder",
          component: () => import("@/view/pages/Orders/ViewOrder.vue"),
          meta: { requiresAuth: true },
        },
        {
          path: "/share-location",
          name: "ShareLocation",
          component: () => import("@/view/pages/other/ShareLocation.vue"),
          meta: { requiresAuth: false },
        },
        {
          path:"/sms",
          name:"SMS",
          component:()=> import("@/view/pages/user/SMS.vue"),
          meta: { requiresAuth: false },
        },
       
        {
          path:"/admin/zones",
          name:"ZonesManager",
          component:()=> import("@/view/pages/zones manager/ZonesManager.vue"),
          meta: { requiresAuth: true },
        },
        {
          path:"/admin/zones/new-zone",
          name:"NewZone",
          component:()=> import("@/view/pages/zones manager/CreateZone.vue"),
          meta: { requiresAuth: true },
        },
        {
          path:"/admin/zones/edit-zone",
          name:"EditZone",
          component:()=> import("@/view/pages/zones manager/ZoneEdit.vue"),
          meta: { requiresAuth: true },
        },
        {
          path:"/admin/customers",
          name:"NewCustomer",
          component:()=> import("@/view/pages/customers/Customers.vue"),
          meta: { requiresAuth: true },
        },
        {
          path:"/admin/customers/:id",
          name:"ViewCustomer",
          component:()=> import("@/view/pages/customers/ViewCustomer.vue"),
          meta: { requiresAuth: true },
          props:true
        },
        {
          path:"/admin/general-settings",
          name:"GeneralSettings",
          component:()=> import("@/view/pages/general settings/GeneralSettings.vue"),
          meta: { requiresAuth: true },
        },
        {
          path:"/admin/users",
          name:"Users",
          component:()=> import("@/view/pages/Users/Users.vue"),
          meta: { requiresAuth: true },
        },
        {
          path:"/admin/users/new-user",
          name:"NewUser",
          component:()=> import("@/view/pages/Users/CreateUser.vue"),
          meta: { requiresAuth: true },
        },
        {
          path:"/admin/users/:id",
          name:"ViewUser",
          component:()=> import("@/view/pages/Users/VuewUser.vue"),
          meta: { requiresAuth: true },
        },
        {
          path:"/admin/schedule-manager",
          name:"ScheduleManager",
          component:()=> import("@/view/pages/ScheduleManager/ScheduleManager.vue"),
          meta: { requiresAuth: true },
        },
        {
          path:"/admin/schedule-manager/create",
          name:"CreateScheduleManager",
          component:()=> import("@/view/pages/ScheduleManager/Create.vue"),
          meta: { requiresAuth: true },
        },
        {
          path:"/admin/schedule-manager/:id",
          name:"ViewSchedule",
          component:()=> import("@/view/pages/ScheduleManager/ViewSchedule.vue"),
          meta: { requiresAuth: true },
        },


        {
          path:"/admin/driver-tarrif",
          name:"driverTarrif",
          component:()=> import("@/view/pages/DriverTarrif/DriverTarrif.vue"),
          meta: { requiresAuth: true },
        },
        {
          path:"/admin/driver-tarrif/create",
          name:"CreateTarrif",
          component:()=> import("@/view/pages/DriverTarrif/CreateTarrif.vue"),
          meta: { requiresAuth: true },
        },
        {
          path:"/admin/driver-tarrif/view-tarrif",
          name:"EditTarrif",
          component:()=> import("@/view/pages/DriverTarrif/ViewTarrif.vue"),
          meta: { requiresAuth: true },
        },

        {
          path:"/admin/clients-tarrif",
          name:"clientstarrif",
          component:()=> import("@/view/pages/ClientsTarrif/ClientsTarrif.vue"),
          meta: { requiresAuth: true },
        },
        {
          path:"/admin/clients-tarrif/create",
          name:"CreateTarrif",
          component:()=> import("@/view/pages/ClientsTarrif/CreateTarrif.vue"),
          meta: { requiresAuth: true },
        },
        {
          path:"/admin/clients-tarrif/:id",
          name:"EditTarrifClients",
          component:()=> import("@/view/pages/ClientsTarrif/ViewTarrif.vue"),
          meta: { requiresAuth: true },
        },
        {
          path:"/admin/pending-transactions",
          name:"PendingTransactions",
          component:()=> import("@/view/pages/PendingTransactions/PendingTransactions.vue"),
          meta: { requiresAuth: true },
        },
        {
          path:"/admin/all-transactions",
          name:"AllTransactions",
          component:()=> import("@/view/pages/AllTransactions/AllTransactions.vue"),
          meta: { requiresAuth: true },
        },
      ]
    },
    {
      path:'/Clients',
      redirect: "/Clients/dashboard",
      component: () => import("@/view/DriverLayout/Layout"),
      children:[
        {
          path:'/Clients/dashboard',
          component: () => import("@/view/pages/DriverPanel/Dashboard.vue")
        },
        {
          path:'/Clients/DeliveryRequests',
          name:'RequestDriver',
          component: () => import("@/view/pages/DriverPanel/RequestDriver/RequestDriver.vue")
        },
        {
          path:'/Clients/Requests-History',
          name:'RequestHistory',
          component: () => import("@/view/pages/DriverPanel/RequestHistory/RequestHistory.vue")
        },
        {
          path:'/Clients/Requests-History/:id',
          name:'ViewRequest',
          component: () => import("@/view/pages/DriverPanel/RequestHistory/ViewRequest.vue")
        },
        {
          path:'/Clients/Requests-History/:id',
          name:'ViewPickup',
          component: () => import("@/view/pages/DriverPanel/RequestHistory/ViewPickup.vue")
        },
        {
          path:'/Clients/Requests-History/:id',
          name:'ViewDelivery',
          component: () => import("@/view/pages/DriverPanel/RequestHistory/ViewDelivery.vue")
        },
        {
          path:'/Clients/Zones-Price',
          name:'ZonesPrice',
          component: () => import("@/view/pages/DriverPanel/ZonesPrice/ZonesPrice.vue")
        },
      ]

    },
    {
      path: "/",
      // redirect: "/login",
      component: () => import("@/view/pages/auth/login_pages/Login-1"),
      children: [
        {
          name: "login",
          path: "/login",
          component: () => import("@/view/pages/auth/login_pages/Login-1"),
          meta: { requiresAuth: false },
        },
        {
          name: "register",
          path: "/register",
          component: () => import("@/view/pages/auth/login_pages/Login-1"),
          meta: { requiresAuth: false },
        }
      ]
    },
    {
      path: "*",
      redirect: "/404"
    },
    {
      path:"/Confirmed_Order_Successfully/:id",
      name:"Confirmed",
      component:()=> import("@/view/pages/user/ConfirmedOrder.vue"),
      meta: { requiresAuth: false },
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => import("@/view/pages/error/Error-1.vue")
    }
  ]
});
