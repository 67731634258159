import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
// import cookie from "cookie";

// import JwtService from "@/core/services/jwt.service";

/**
 * Service to call HTTP request via Axios
 */

const ApiService = window.Api = {
  init() {
    window.axios = axios;
    axios.defaults.crossDomain = true;
    axios.defaults.withCredentials = true
    Vue.use(VueAxios, axios);
    //Vue.axios.defaults.baseURL = "https://api.we-deliver.co/";
    Vue.axios.defaults.baseURL = "https://apidev.vaiila.com";
    //Vue.axios.defaults.baseURL = "http://wedeliver.test/";
    //Vue.axios.defaults.baseURL = "http://devback.we-deliver.co/"
    //Vue.axios.defaults.baseURL = "http://localhost:127.0.0.1/";
    Vue.axios.defaults.headers = { 
      "content-type": "application/json",
      "accept": "application/json",
      // "X-XSRF-TOKEN" : cookie.parse(document.cookie)['XSRF-TOKEN'],
      "Authorization" : 'Bearer '+ localStorage.getItem('token'),
    }
  },

  /**
   * Set the default HTTP request headers
   */
  setHeader() {
    // Vue.axios.defaults.headers = {
      
    // };
  },

  query(resource, params) {
    return Vue.axios.get(resource, params).catch(() => {
    });
  },

  /**
   * Send the GET HTTP request
   * @param resource
   * @param slug
   * @returns {*}
   */
  get(resource, slug = "") {
    return Vue.axios.get(`${resource}/${slug}`);
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  post(resource, params) {
    return Vue.axios.post(`${resource}`, params);
  },

  /**
   * Send the UPDATE HTTP request
   * @param resource
   * @param slug
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  update(resource, slug, params) {
    return Vue.axios.put(`${resource}/${slug}`, params);
  },

  /**
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  put(resource, params) {
    return Vue.axios.put(`${resource}`, params);
  },

  /**
   * Send the DELETE HTTP request
   * @param resource
   * @returns {*}
   */
  delete(resource) {
    return Vue.axios.delete(resource).catch(() => {
    });
  }
};

export default ApiService;
