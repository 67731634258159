/* eslint-disable no-unused-vars */
import store from "@/core/services/store";
import ApiService from "@/core/services/api.service";

export default {
    namespaced: true,
    state: {
        admin: [],
    },
    mutations: {
        SET_ADMIN(state, admin) {
            state.admin = admin;
            
        },
    },
    actions: {
        async dashboard({ commit }) {
            let response = await ApiService.get('api/v1/admin/dashboard');
            let admin = response.data;            
            commit('SET_ADMIN', admin);
            return response;
        },

        async chart({ commit }) {
            let response = await ApiService.get('api/v1/orders/dashboard');
            return response;
        },
       
    },
    
}