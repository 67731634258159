/* eslint-disable no-unused-vars */
import store from "@/core/services/store";
import ApiService from "@/core/services/api.service";

export default {
    namespaced: true,
    state: {
        cuser: [],
    },
    mutations: {
        SET_CUSER(state, data) {
            state.cuser = data.data;
            //console.log(state.cuser)
        },
    },
    actions: {
        async setCuser({ commit },data) {
            //let response = await ApiService.get('api/v1/clients');
           // let clients = response.data.data; 
                    
            commit('SET_CUSER', data);
            //return response;
        },
       
        
    },
    
}