/* eslint-disable no-unused-vars */
import store from "@/core/services/store";
import ApiService from "@/core/services/api.service";

export default {
    namespaced: true,
    state: {
        queues: [],
    },
    mutations: {
        SET_QUEUES(state, queues) {
            state.queues = queues;
        },
        ADD_QUEUE(state, queue) {
            let queues = state.queues.concat(queue);
            state.queues = queues;
        },
        DELETE_QUEUE(state, queueId) {
            let queues = state.queues.filter(q => q.id != queueId);
            state.queues = queues;
        },
        UPDATE_QUEUE(state, queue) {
            let updatedqueue = state.queues.find(q => q.id == queue.id);
            updatedqueue = queue;
        },
        LOAD_PAGES(state, data){
            if(state.queues.findIndex(x=>x.id === data[0].id)===-1){
                state.queues = state.queues.concat(data)
            }
        }
    },
    actions: {
        async loadAll({ commit }) {
            let response = await ApiService.get('api/v1/queues');
            let queues = response.data.data;            
            commit('SET_QUEUES', queues);
            return response;
        },
        async loadPage({ commit},pageNumber ) {
            let response = await ApiService.get(`api/v1`,`queues?page=${pageNumber}`);
            var data = response.data.data
            commit('LOAD_PAGES', data);
            
            return response
        },
        async create({ commit }, queue) {
            let response = await ApiService.post('api/v1/queues', queue);
            let savedQueue = response.data;
            commit('ADD_QUEUE', savedQueue);
            return savedQueue;
        },
        async delete({ commit }, queue) {
            let response = await ApiService.delete(`api/v1/queues/${queue.id}`);
            if (response.status == 200 || response.status == 204) {
                commit('DELETE_QUEUE', queue.id);
            }
        },
        async update({ commit }, queue) {
            let response = await ApiService.update("api/v1/queues", queue.id, queue);
            let newQueue = response.data;
            commit('UPDATE_QUEUE', newQueue);
            return newQueue;
        },
        async fetchCreateData({ commit }) {
            let response = await ApiService.get(`api/v1/queues/create`)
            return response.data.meta;
        },
    },
    getters: {
        table: (state) => {
            return {
                fields: [
                    {
                        key: "id",
                        label: "ID",
                        sortable: true,
                        sortDirection: "desc",
                      },
                      {
                        key: "name",
                        label: "Name",
                        sortable: true,
                        sortDirection: "desc",
                      },
                      {
                        key: "clients",
                        label: "Clients",
                        sortable: true,
                        sortDirection: "desc",
                      },
                      {
                        key: "drivers",
                        label: "Drivers",
                        sortable: true,
                        sortDirection: "desc",
                      },
                      { key: "actions", label: "Actions" },
                ],

                items: state['queues'],
            }
        },
        tableAR: (state) => {
            return {
                fields: [
                    {
                        key: "id",
                        label: "المعرف",
                        sortable: true,
                        sortDirection: "desc",
                      },
                      {
                        key: "name",
                        label: "الإسم",
                        sortable: true,
                        sortDirection: "desc",
                      },
                      {
                        key: "clients",
                        label: "الوكلاء",
                        sortable: true,
                        sortDirection: "desc",
                      },
                      {
                        key: "drivers",
                        label: "السائقين",
                        sortable: true,
                        sortDirection: "desc",
                      },
                    { key: "actions", label: "إعدادات" },
                     
                      
                ],

                items: state['queues'],
            }
        }
    },
}