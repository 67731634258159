/* eslint-disable no-unused-vars */
import store from "@/core/services/store";
import ApiService from "@/core/services/api.service";

export default {
    namespaced: true,
    state: {
        dschedule: [],
    },
    mutations: {
        SET_DRIVERSCHEDULES(state, dschedule) {
            state.dschedule = dschedule;
        },
        ADD_DRIVERSCHEDULES(state, schedule) {
            let dschedule = state.dschedule.concat(schedule);
            state.dschedule = dschedule;
        },
        DELETE_DRIVERSCHEDULES(state, dscheduleId) {
            let scedule = state.dschedule.filter(d => d.id != dscheduleId);
            state.dschedule = scedule;
        },
        UPDATE_DRIVERSCHEDULES(state, schedule) {
            let updatedDriver = state.dschedule.find(d => d.id == schedule.id);
            updatedDriver = schedule;
        },
        LOAD_PAGES(state, data){
            if(state.dschedule.findIndex(x=>x.id === data[0].id)===-1){
                state.dschedule = state.dschedule.concat(data)
            }
        }
    },
    actions: {
        async loadAll({ commit }) {
            let response = await ApiService.get('api/v1/workingDays/drivers');
            //console.log(response.data.data.data)
            console.log(response)
            let driverSchedules = response.data.data;
            commit('SET_DRIVERSCHEDULES', driverSchedules);
            //console.log(driverSchedules)
            return response;
            
        },
        async loadPage({ commit},pageNumber ) {
            let response = await ApiService.get(`api/v1/workingDays`,`drivers?page=${pageNumber}`);
            var data = response.data
            
            commit('LOAD_PAGES', data.data);
            
            return response
        },
        async create({ commit }, driverSchedule) {
            let response = await ApiService.post('api/v1/schedule/drivar/create', driverSchedule);
            let saveddriverSchedule = response.data;
            commit('ADD_DRIVERSCHEDULES', saveddriverSchedule);
            return saveddriverSchedule;
        },
        async delete({ commit }, schedule) {
            let response = await ApiService.delete(`api/v1/schedule/drivar/delete/${schedule.id}`);
            if (response.status == 200 || response.status == 204) {
                commit('DELETE_DRIVERSCHEDULES', schedule.id);
            }
        },
        async update({ commit }, schedule) {
            let response = await ApiService.update("api/v1/schedule/drivar/update", schedule.id, schedule);
            let newDriver = response.data;
            commit('UPDATE_DRIVERSCHEDULES', newDriver);
            return newDriver;
        },
    },
    getters: {
        table: (state) => {
            return {
                fields: [
                    
                    {
                        key: "full_name",
                        label: "Name",
                        //class: "text-center",
                        class:'border w--lg-300px'
                        
                    },
                    {
                        key: "sundayfrom",
                        label: "From",
                        thClass: "",
                        class:'border text-center w-lg-100px'
                    },
                    {
                        key: "sundayto",
                        label: "To",
                        thClass: "",
                        class:'border text-center w-lg-100px'
                    },
                    {
                        key: "mondayfrom",
                        label: "From",
                        thClass: "",
                        class:'border text-center w-lg-100px'
                    },
                    {
                        key: "mondayto",
                        label: "To",
                        thClass: "",
                        class:'border text-center w-lg-100px'
                    },
                    {
                        key: "tuesdayfrom",
                        label: "From",
                        thClass: "",
                        class:'border text-center w-lg-100px'
                    },
                    {
                        key: "tuesdayto",
                        label: "To",
                        thClass: "",
                        class:'border text-center w-lg-100px'
                    },
                    {
                        key: "wefrom",
                        label: "From",
                        thClass: "",
                        class:'border text-center w-lg-100px'
                    },
                    {
                        key: "weto",
                        label: "To",
                        thClass: "",
                        class:'border text-center w-lg-100px'
                    },
                    {
                        key: "thfrom",
                        label: "From",
                        thClass: "",
                        class:'border text-center w-lg-100px'
                    },
                    {
                        key: "thto",
                        label: "To",
                        thClass: "",
                        class:'border text-center w-lg-100px'
                    },
                    {
                        key: "frfrom",
                        label: "From",
                        thClass: "",
                        class:'border text-center w-lg-100px'
                    },
                    {
                        key: "frto",
                        label: "To",
                        thClass: "",
                        class:'border text-center w-lg-100px'
                    },
                    {
                        key: "satfrom",
                        label: "From",
                        thClass: "",
                        class:'border text-center w-lg-100px'
                    },
                    {
                        key: "satto",
                        label: "To",
                        thClass: "",
                        class:'border text-center w-lg-100px'
                    },
                ],

                items: state['dschedule'],
            }
        },
        tableAR: (state) => {
            return {
                fields: [
                    {
                        key: "id",
                        label: "المعرف",
                        sortable: true,
                        sortDirection: "desc",
                    },
                    {
                        key: "full_name",
                        label: "الإسم الكامل",
                        sortable: true,
                        sortDirection: "desc",
                    },
                    {
                        key: "phone",
                        label: "رقم الجوال",
                        sortable: true,
                        sortDirection: "desc",
                    },
                    {
                        key: "national",
                        label: "الرقم الوطني",
                        sortable: true,
                        sortDirection: "desc",
                    },

                    { key: "actions", label: "إعدادات" },
                ],

                items: state['drivers'],
            }
        }
    },
}