/* eslint-disable no-unused-vars */
import store from "@/core/services/store";
import ApiService from "@/core/services/api.service";

export default {
    namespaced: true,
    state: {
        customers: [
            /*{ id: 1, name:'Ahmad Mohammad' , phone:'0788951124',Onumber:'645',
        address:{
            streetName:'Jordan Street',area:'Abdoun',building:'B84',floor:'L4',type:'--'
        },
     },
        { id: 2, name:'Mohamamd Malkawi' , phone:'0781257124',Onumber:'423',
        address:{
            streetName:'Airport Street',area:'Al Abdali',building:'X6',floor:'L0',type:'--'
        } ,
     },
        { id: 3, name:'Samer Ahmad' , phone:'0794251189',Onumber:'120',
        address:{
            streetName:'Alia Street',area:'Al rabia',building:'XYT6',floor:'L2',type:'--'
        } ,
      },
        { id: 4, name:'Majdi Mostafa' , phone:'0778983621',Onumber:'95',
        address:{
            streetName:'Alia Street',area:'Al rabia',building:'XYT6',floor:'L2',type:'--'
        } ,
      },     */   
        ],
    },
    mutations: {
        SET_CUSTOMERS(state, customers) {
            state.customers = customers;
        },
        ADD_CUSTOMER(state, customer) {
            let customers = state.customers.concat(customer);
            state.customers = customers;
        },
        DELETE_CUSTOMER(state, customerId) {
            let customers = state.customers.filter(d => d.id != customerId);
            state.customers = customers;
        },
        UPDATE_CUSTOMER(state, customer) {
            let updatedCustomer = state.customers.find(d => d.id == customer.id);
            updatedCustomer = customer;
        },
        LOAD_PAGES(state, data){
            if(state.customers.findIndex(x=>x.id === data[0].id)===-1){
                state.customers = state.customers.concat(data)
            }
        }
    },
    actions: {
        async loadAll({ commit }) {
            let response = await ApiService.get('api/v1/customers');
            let customers = response.data.data;            
            commit('SET_CUSTOMERS', customers);
            return response;
        },
        async search({ commit },value) {
            let response = await ApiService.get('api/v1',`customers?filter=${value}`);
            let customers = response.data.data;            
            commit('SET_CUSTOMERS', customers);
            return response;
        },
        async loadPage({ commit},pageNumber ) {
            let response = await ApiService.get(`api/v1`,`customers?page=${pageNumber}`);
            var data = response.data.data
            commit('LOAD_PAGES', data);
            
            return response
        },
        async create({ commit }, customer) {
            let response = await ApiService.post('api/v1/customers', customer);
            let savedCustomer = response.data;
            commit('ADD_CUSTOMER', savedCustomer);
            console.log(savedCustomer)
            return savedCustomer;
        },
        async delete({ commit }, customer) {
            let response = await ApiService.delete(`api/v1/customers/${customer.id}`);
            if (response.status == 200 || response.status == 204) {
                commit('DELETE_CUSTOMER', customer.id);
            }
        },
        async update({ commit }, customer) {
            let response = await ApiService.update("api/v1/customers", customer.id, customer);
            let newCustomer = response.data;
            commit('UPDATE_CUSTOMER', newCustomer);
            return newCustomer;
        },
    },
    getters: {
        table: (state) => {
            return {
                fields: [
                    {
                        key: "id",
                        label: "ID",
                        sortable: true,
                        sortDirection: "desc",
                      },
                    {
                      label: "Name",
                      key: "name",
                    },
                    {
                      label: "Phone",
                      key: "phone",
                      
                    },
                    {
                        label: "Email",
                        key: "email",
                        
                      },
                    {
                      label: "# of Orders",
                      key: "Onumber",
                      type: "number",
                    },
                    { key: "actions", label: "Actions" },
                  ],

                  items: state['customers'],
            }
        },
        tableAR: (state) => {
            return {
                fields: [
                    {
                        key: "id",
                        label: "المعرف",
                        sortable: true,
                        sortDirection: "desc",
                      },
                    {
                      label: "الإسم",
                      key: "name",
                    },
                    {
                      label: "رقم الجوال",
                      key: "phone",
                      
                    },
                    {
                        label: "البريد الإلكتروني",
                        key: "email",
                        
                      },
                    {
                      label: "عدد الطلبات",
                      key: "Onumber",
                      type: "number",
                    },
                    { key: "actions", label: "إعدادات" },
                  ],

                  items: state['customers'],
            }
        },
        
    },
}