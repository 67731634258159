/* eslint-disable no-unused-vars */
import store from "@/core/services/store";
import ApiService from "@/core/services/api.service";

export default {
    namespaced: true,
    state: {
        orders: [],
    },
    mutations: {
        SET_ORDERS(state, orders) {
            state.orders = orders;
            //console.log(orders)
            
        },
        ADD_ORDER(state, order) {
            let orders = state.orders.concat(order);
            state.orders = orders;
        },
        DELETE_ORDER(state, orderId) {
            let orders = state.orders.filter(o => o.id != orderId);
            state.orders = orders;
        },
        UPDATE_ORDER(state, order) {
            let updatedorder = state.orders.find(o => o.id == order.id);
            updatedorder = order;
        },
        LOAD_PAGES(state, data){
            if(state.orders.findIndex(x=>x.id === data[0].id)===-1){
                state.orders = state.orders.concat(data)
            }
        }
    },
    actions: {
        async loadAll({ commit }) {
            let response = await ApiService.get('api/v1/orders/all');
            let orders = response.data.data;           
            commit('SET_ORDERS', orders);

            
            return response
        },
        async search({ commit },value) {
            let response = await ApiService.get('api/v1/orders',`all?filter=${value}`);
            let orders = response.data.data;           
            commit('SET_ORDERS', orders);

            
            return response
        },
        async loadPage({ commit},pageNumber ) {
            let response = await ApiService.get(`api/v1/orders`,`all?page=${pageNumber}`);
            var data = response.data.data
            commit('LOAD_PAGES', data);
            
            return response
        },
        async getOne({ commit }, order_id) {
            let response = await ApiService.get(`api/v1/orders/${order_id}/show`);
            let order = response.data.data;            

            return order;
        },
        async create({ commit }, order) {
            let response = await ApiService.post('api/v1/orders', order);
            let savedOrder = response.data;
            commit('ADD_ORDER', savedOrder);
            return savedOrder;
        },
        async orderAdmin({ commit }, order) {
            let response = await ApiService.post('api/v1/orders/adminOrder', order);
            let savedOrder = response.data;
            return savedOrder;
        },
        async delete({ commit }, order) {
            let response = await ApiService.delete(`api/v1/orders/${order.id}`);
            if (response.status == 200 || response.status == 204) {
                commit('DELETE_ORDER', order.id);
            }
        },
        async update({ commit }, order) {
            let response = await ApiService.update("api/v1/orders", order.id, order);
            let newOrder = response.data;
            commit('UPDATE_ORDER', newOrder);
            return newOrder;
        },
        async sendLocation({commit}, payload) {
            let response = await ApiService.post(`api/v1/orders/${payload.order_id}/edit-task`, payload.location);

            return response;
        },
        async createAddress({commit}, payload) {
            let response = await ApiService.post(`api/v1/addresses`, payload);

            return response;
        },
        async deleteAddress({commit}, payload) {
            let response = await ApiService.delete(`api/v1/addresses/${payload.id}`);
        },
        async editTask({commit}, payload) {
            let response = await ApiService.post(`api/v1/orders/${payload.order_id}/edit-task`, {
                longitude: payload.longitude,
                latitude: payload.latitude,
                fees: payload.fees,
            });

            return response;
        },
        async fetchFees({commit}, payload) {
            let response = await ApiService.post(`api/v1/orders/${payload.order_id}/fetch-fees`, {
                longitude: payload.longitude,
                latitude: payload.latitude,
            });
            

            return response.data;
        },
        async cancelTask({commit}, payload) {
            let response = await ApiService.post(`api/v1/orders/${payload.id}/cancel`);
            

            return response.data;
        },

        async orderHistory({commit}, data) {
            let response = await ApiService.get(`api/v1/orders/history/${data.client_id}/${data.from}/${data.to}/${data.status}`);
            //console.log(response)
            var orders = response.data.data
            commit('SET_ORDERS', orders);

            return response.data;
        },
        async customerOrder({commit}, customer_id) {
            let response = await ApiService.get(`api/v1/customer/orders/${customer_id}`);
            
            return response;
        },
        async loadPageC({ commit},data ) {
            let response = await ApiService.get(`api/v1/orders/history/${data.client_id}/${data.from}/${data.to}/${data.status}`,`?page=${data.pageNumber}`);
            var dataC = response.data.data
            commit('LOAD_PAGES', dataC);
            //console.log(response)
            
            return response
        },

        async getPlaceDetails({commit},value ) {
            //console.log(value.place_id)
            let response = await ApiService.get(`api/v1/Map/place/${value.place_id}`);
            
            return response.data.result.geometry;


        },
    },
    getters: {
        table: (state) => {
            return {
                fields: [
                    {
                        label: 'ID',
                        key: 'id',
                        sortable: true,
                        sortDirection: "desc",
                    },
                    {
                        label: "Client",
                        key: 'client.name',
                        sortable: true,
                        sortDirection: "desc",
                    },
                    {
                        label: "customer",
                        key: 'customer',
                        sortable: true,
                        sortDirection: "desc",
                    },
                    {
                        label: "Driver",
                        key: 'driver.full_name',
                        sortable: true,
                        sortDirection: "desc",
                    },
                    {
                        label: "Driver Phone",
                        key: 'driver.phone',
                        sortable: true,
                        sortDirection: "desc",
                    },
                    {
                        label: "Type",
                        key: 'vehicle_type',
                        sortable: true,
                        sortDirection: "desc",
                    },
                    {
                        label: "Notes",
                        key: 'notes',
                        sortable: true,
                        sortDirection: "desc",
                    },
                    {
                        label: "Date",
                        key: 'delivery_date',
                        sortable: true,
                        sortDirection: "desc",
                    },
                    {
                        label: "Status",
                        key: 'status',
                        sortable: true,
                        sortDirection: "desc",
                    },
                    {
                        label: "Is Address Confirmed?",
                        key: 'isAddressConfirmed',
                        sortable: true,
                        sortDirection: "desc",
                    },
                    {
                        label: "Zone",
                        key: 'zone_id',
                        sortable: true,
                        sortDirection: "desc",
                    },
                    {
                        label: "Fees",
                        key: 'fees',
                        sortable: true,
                        sortDirection: "desc",
                    },
                    {
                        label: "Amount",
                        key: 'amount',
                        sortable: true,
                        sortDirection: "desc",
                    },
                    {
                        label: "Actions",
                        key: 'actions',
                        sortable: true,
                        sortDirection: "desc",
                    },
                ],

                items: state['orders'],
            }
        },
        tableAR: (state) => {
            return {
                fields: [
                    {
                        label: 'المعرف',
                        key: 'id',
                        sortable: true,
                        sortDirection: "desc",
                    },
                    {
                        label: "الوكيل",
                        key: 'client.name',
                        sortable: true,
                        sortDirection: "desc",
                    },
                    {
                        label: "العميل",
                        key: 'customer',
                        sortable: true,
                        sortDirection: "desc",
                    },
                    {
                        label: "السائق",
                        key: 'driver.full_name',
                        sortable: true,
                        sortDirection: "desc",
                    },
                    {
                        label: "جوال السائق",
                        key: 'driver.phone',
                        sortable: true,
                        sortDirection: "desc",
                    },
                    {
                        label: "النوع",
                        key: 'vehicle_type',
                        sortable: true,
                        sortDirection: "desc",
                    },
                    {
                        label: "ملاحظات",
                        key: 'notes',
                        sortable: true,
                        sortDirection: "desc",
                    },
                    {
                        label: "التاريخ",
                        key: 'delivery_date',
                        sortable: true,
                        sortDirection: "desc",
                    },
                    {
                        label: "الحالة",
                        key: 'status',
                        sortable: true,
                        sortDirection: "desc",
                    },
                    {
                        label: "هل تم تأكيد الموقع؟",
                        key: 'isAddressConfirmed',
                        sortable: true,
                        sortDirection: "desc",
                    },
                    {
                        label: "المنطقة",
                        key: 'zone_id',
                        sortable: true,
                        sortDirection: "desc",
                    },
                    {
                        label: "الرسوم",
                        key: 'fees',
                        sortable: true,
                        sortDirection: "desc",
                    },
                    {
                        label: "المبلغ",
                        key: 'amount',
                        sortable: true,
                        sortDirection: "desc",
                    },
                    {
                        label: "إعدادات",
                        key: 'actions',
                        sortable: true,
                        sortDirection: "desc",
                    },
                ],

                items: state['orders'],
            }
        },
        table2: (state) => {
            return {
                fields: [
                    {
                        label: "Customer",
                        key: 'customer.name',
                        sortable: true,
                        sortDirection: "desc",
                    },
                    {
                        label: "Phone",
                        key: 'customer.phone',
                        sortable: true,
                        sortDirection: "desc",
                    },
                    {
                        label: "Date",
                        key: 'created_at',
                        sortable: true,
                        sortDirection: "desc",
                    },
                    {
                        label: "Driver",
                        key: 'driver.full_name',
                        sortable: true,
                        sortDirection: "desc",
                    },
                    {
                        label: "Driver Phone",
                        key: 'driver.phone',
                        sortable: true,
                        sortDirection: "desc",
                    },
                    {
                        label: "Amount",
                        key: 'amount',
                        sortable: true,
                        sortDirection: "desc",
                    },
                    {
                        label: "Fees",
                        key: 'fees',
                        sortable: true,
                        sortDirection: "desc",
                    },
                    {
                        label: "Delivered At",
                        key: 'delivered_at',
                        sortable: true,
                        sortDirection: "desc",
                    },
                    {
                        label: "PickedUp At",
                        key: 'pickedup_at',
                        sortable: true,
                        sortDirection: "desc",
                    },
                    {
                        label: "Zone",
                        key: 'zone_id',
                        sortable: true,
                        sortDirection: "desc",
                    },
                    {
                        label: "Status",
                        key: 'status',
                        sortable: true,
                        sortDirection: "desc",
                    },
                    {
                        label: "Is Address Confirmed?",
                        key: 'isAddressConfirmed',
                        sortable: true,
                        sortDirection: "desc",
                    },
                    {
                        label: "Actions",
                        key: 'actions',
                        sortable: true,
                        sortDirection: "desc",
                    },
                ],

                items: state['orders'],
            }
        },
        table2AR: (state) => {
            return {
                fields: [
                    {
                        label: "إعدادات",
                        key: 'actions',
                        sortable: true,
                        sortDirection: "desc",
                    },
                    {
                        label: "هل تم تأكيد الموقع؟",
                        key: 'isAddressConfirmed',
                        sortable: true,
                        sortDirection: "desc",
                    },
                    {
                        label: "الحالة",
                        key: 'status',
                        sortable: true,
                        sortDirection: "desc",
                    },
                    {
                        label: "المنطقة",
                        key: 'zone_id',
                        sortable: true,
                        sortDirection: "desc",
                    },
                    {
                        label: "تم الإستلام في",
                        key: 'pickedup_at',
                        sortable: true,
                        sortDirection: "desc",
                    },
                    {
                        label: "تم التوصيل في",
                        key: 'delivered_at',
                        sortable: true,
                        sortDirection: "desc",
                    },
                    {
                        label: "المصاريف",
                        key: 'fees',
                        sortable: true,
                        sortDirection: "desc",
                    },
                    {
                        label: "السعر",
                        key: 'amount',
                        sortable: true,
                        sortDirection: "desc",
                    },
                    {
                        label: "رقم السائق",
                        key: 'driver.phone',
                        sortable: true,
                        sortDirection: "desc",
                    },
                    {
                        label: "السائق",
                        key: 'driver.full_name',
                        sortable: true,
                        sortDirection: "desc",
                    },
                    {
                        label: "التاريخ",
                        key: 'created_at',
                        sortable: true,
                        sortDirection: "desc",
                    },
                    {
                        label: "رقم العميل",
                        key: 'customer.phone',
                        sortable: true,
                        sortDirection: "desc",
                    },
                    {
                        label: "العميل",
                        key: 'customer.name',
                        sortable: true,
                        sortDirection: "desc",
                    },
                    
                    
                    
                    
                    
                   
                   
                    
                ],

                items: state['orders'],
            }
        }
    },
}