import Vue from "vue";
import Vuex from "vuex";

import auth from "./auth.module";
import htmlClass from "./htmlclass.module";
import config from "./config.module";
import breadcrumbs from "./breadcrumbs.module";
import profile from "./profile.module";
import clients from "./clients.module"
import orders from "./orders.module"
import queues from "./queues.module"
import drivers from "./drivers.module"
import customers from "./customers.module"
import corders from './corders.module'
import zones from './zones.module'
import users from './user.module'
import admin from './admin.module'
import cuser from './cuser.module'
import dschedule from './dschedule.module'
import clientTarrif from './clientTarrif.module'
import transactions from './transactions.module'
import alltransactions from './alltransactions.module'

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    htmlClass,
    config,
    breadcrumbs,
    profile,
    clients,
    orders,
    queues,
    drivers,
    customers,
    corders,
    zones,
    users,
    admin,
    cuser,
    dschedule,
    clientTarrif,
    transactions,
    alltransactions
  }
});
