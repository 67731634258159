/* eslint-disable no-unused-vars */
import store from "@/core/services/store";
import ApiService from "@/core/services/api.service";

export default {
    namespaced: true,
    state: {
        zones: [],
    },
    mutations: {
        SET_ZONES(state, zones) {
            state.zones = zones
        },
        ADD_ZONE(state, zone) {
            let zones = state.zones.concat(zone);
            state.zones = zones;
        },
        DELETE_ZONE(state, zoneId) {
            let zones = state.zones.filter(d => d.id != zoneId);
            state.zones = zones;
        },
        UPDATE_ZONE(state, zone) {
            let updatedZone = state.zones.find(d => d.id == zone.id);
            updatedZone = zone;
        },
        LOAD_PAGES(state, data){
            if(state.zones.findIndex(x=>x.id === data[0].id)===-1){
                state.zones = state.zones.concat(data)
            }
        }
    },
    actions: {
        async loadAll({ commit }) {
            let response = await ApiService.get('api/v1/zones');
            let zones = response.data.data;            
            commit('SET_ZONES', zones);
            return response
        },
        async loadPage({ commit},pageNumber ) {
            let response = await ApiService.get(`api/v1`,`zones?page=${pageNumber}`);
            var data = response.data.data
            commit('LOAD_PAGES', data);
            
            return response
        },
        async create({ commit }, zone) {
            let response = await ApiService.post('api/v1/zones', zone);
            let savedZone = response.data;
            commit('ADD_ZONE', savedZone);
            console.log(savedZone)
            return savedZone;
            
        },
        async delete({ commit }, zone) {
            let response = await ApiService.delete(`api/v1/zones/${zone.id}`);
            if (response.status == 200 || response.status == 204) {
                commit('DELETE_ZONE', zone.id);
            }
        },
        async update({ commit }, zone) {
            let response = await ApiService.update("api/v1/zones", zone.id, zone);
            let newZone = response.data;
            commit('UPDATE_ZONE', newZone);
            return newZone;
        },
    },
    getters: {
        table: (state) => {
            return {
                fields: [
                    {
                      label: "Name(Arabic)",
                      key: "name_ar",
                      sortable: true,
                    sortDirection: "desc",
                    },
                    {
                      label: "Name(English)",
                      key: "name_en",
                      sortable: true,
                        sortDirection: "desc",
                    },
                    {
                        label: "Price(JOD)",
                        key: "price",
                        type: "number",
                        sortable: true,
                        sortDirection: "desc",
                      },
                      {
                        label: "Displayed Price(JOD)",
                        key: "displayed_price",
                        type: "number",
                        sortable: true,
                        sortDirection: "desc",
                      },
                    {
                      label: "Tookan Region ID",
                      key: "tookan_region_id",
                      sortable: true,
                        sortDirection: "desc",
                      
                    },
                    { key: "actions", label: "Actions" },
                  ],

                  items: state['zones'],
            }
        },
        tableAR: (state) => {
            return {
                fields: [
                    {
                      label: "الإسم (بالعربية)",
                      key: "name_ar",
                      sortable: true,
                    sortDirection: "desc",
                    },
                    {
                      label: "الإسم (بالإنجليزية)",
                      key: "name_en",
                      sortable: true,
                        sortDirection: "desc",
                    },
                    {
                        label: "السعر (دينار)",
                        key: "price",
                        type: "number",
                        sortable: true,
                        sortDirection: "desc",
                      },
                      {
                        label: "السعر المعروض (دينار)",
                        key: "displayed_price",
                        type: "number",
                        sortable: true,
                        sortDirection: "desc",
                      },
                    {
                      label: "Tookan Region ID",
                      key: "tookan_region_id",
                      sortable: true,
                        sortDirection: "desc",
                      
                    },
                    { key: "actions", label: "إعدادات" },
                  ],

                  items: state['zones'],
            }
        }
    },
}