import ApiService from "@/core/services/api.service";
//import store from "@/core/services/store";
//import store from ".";
import Vue from "vue";




// action types
export const VERIFY_AUTH = "verifyAuth";
export const INITIALIZE_CSRF = "initializeCSRF";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const REGISTER = "register";
export const UPDATE_USER = "updateUser";

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const SET_ERROR = "setError";
export const SET_CRED ='setCred'

const state = {
  errors: null,
  user: {},
  isAuthenticated: false,
  cred :null
};

const getters = {
  currentUser(state) {
    return state.user;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  }
};

const actions = {
  // Initialize CSRF protection: https://laravel.com/docs/7.x/sanctum#spa-authenticating
  [INITIALIZE_CSRF]() {
    return new Promise((resolve, reject) => {
      return ApiService.get("sanctum/csrf-cookie")
        .then(({ data }) => {
          resolve(data);
        })
        .catch(response => {
          reject(response);
        });
    });
  },

  [LOGIN](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.post("api/v1/login", credentials)
        .then(response => {
          if (response.status == 200) {
            Vue.axios.defaults.headers = { 
              "content-type": "application/json",
              "accept": "application/json",
              // "X-XSRF-TOKEN" : cookie.parse(document.cookie)['XSRF-TOKEN'],
              "Authorization" : 'Bearer '+ response.data.data.token,
            }
            //console.log(response.data.data)
            var crypt = 'RTaB8-9541X'+credentials.password+'Q75CTAZ53X'
            context.commit(SET_CRED, credentials);
            localStorage.setItem('email',credentials.email)
            localStorage.setItem('pass',crypt)
            var token = response.data.data.token
    
    localStorage.setItem('token',token)
            localStorage.setItem("name" , response.data.data.name)
            if(response.data.data.type ==='admin'){
              localStorage.setItem('type', 'admin')
            }
            if(response.data.data.type ==='client'){
              localStorage.setItem('type', 'client')
              localStorage.setItem('client',JSON.stringify(response.data.data.client))
            }
           
            
            context.commit(SET_AUTH, response);
            resolve(response);
          }
        })
        .catch(error => {
          // context.commit(SET_ERROR, response.data.errors)//
          reject(error);
        });
    });
  },

  [LOGOUT](context) {
    return new Promise(resolve => {
      ApiService.post("api/v1/logout").then(({ data }) => {
        localStorage.clear()
        context.commit(PURGE_AUTH);
        resolve(data);
      });
    });
  },

  [REGISTER](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.post("register", credentials)
        .then(({ data }) => {
          context.commit(SET_AUTH, data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
          reject(response);
        });
    });
  },

  [VERIFY_AUTH](context) {
    
    
    return new Promise((resolve, reject) => {
      return ApiService.get(`api/v1/user`)
        .then(response => {
          if (response && response.status == 200) {
            //console.log(response.data[0])
            
            context.commit(SET_AUTH, response.data[0]);
            resolve(response);
          } else {
            reject();
            context.commit(PURGE_AUTH);
          }
        })
        .catch(error => {
          context.commit(PURGE_AUTH);
          reject(error);
        });
    });
  },
  

  [UPDATE_USER](context, payload) {
    const { email, username, password, image, bio } = payload;
    const user = { email, username, bio, image };

    if (password) {
      user.password = password;
    }

    return ApiService.put("user", user).then(({ data }) => {
      context.commit(SET_AUTH, data);
      return data;
    });
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },

  [SET_AUTH](state, user) {
    state.isAuthenticated = true;
    state.user = user;
    state.errors = {};
    //console.log(user)
  },
  [SET_CRED](state,credintals){
    state.cred = credintals
  },

  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = {};
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
