/* eslint-disable no-unused-vars */
import store from "@/core/services/store";
import ApiService from "@/core/services/api.service";

export default {
    namespaced: true,
    state: {
        users: [],
    },
    mutations: {
        SET_USERS(state, users) {
            state.users = users;
        },
        ADD_USER(state, user) {
            let users = state.users.concat(user);
            state.users = users;
        },
        DELETE_USER(state, userId) {
            let users = state.users.filter(d => d.id != userId);
            state.users = users;
        },
        UPDATE_USER(state, user) {
            let updatedUser = state.user.find(d => d.id == user.id);
            updatedUser = user;
        },
        LOAD_PAGES(state, data){
            if(state.users.findIndex(x=>x.id === data[0].id)===-1){
                state.users = state.users.concat(data)
            }
        }
    },
    actions: {
        async loadAll({ commit }) {
            let response = await ApiService.get('api/v1/users');
            let users = response.data.data;            
            commit('SET_USERS', users);
            return response;
            
        },
        async loadPage({ commit},pageNumber ) {
            let response = await ApiService.get(`api/v1`,`users?page=${pageNumber}`);
            var data = response.data.data
            commit('LOAD_PAGES', data);
            
            return response;
        },
        async create({ commit }, user) {
            let response = await ApiService.post('api/v1/users', user);
            let savedUser = response.data;
            commit('ADD_USER', savedUser);
            return savedUser;
            
        },
        async delete({ commit }, user) {
            let response = await ApiService.delete(`api/v1/users/${user.id}`);
            if (response.status == 200 || response.status == 204) {
                commit('DELETE_USER', user.id);
            }
        },
        async update({ commit }, user) {
            let response = await ApiService.update("api/v1/users", user.id, user);
            let newUser = response.data;
            commit('UPDATE_USER', newUser);
            return newUser;
        },
    },
    getters: {
        table: (state) => {
            return {
                fields: [
                    {
                      label: "Name",
                      key: "name",
                      sortable: true,
                        sortDirection: "desc",
                    },
                    {
                        label: "Email",
                        key: "email",
                        sortable: true,
                          sortDirection: "desc",
                      },
                      {
                        label: "Type",
                        key: "type",
                        sortable: true,
                          sortDirection: "desc",
                      },
                    { key: "actions", label: "Actions" },
                  ],

                  items: state['users'],
            }
        },
        tableAR: (state) => {
            return {
                fields: [
                    {
                      label: "الإسم",
                      key: "name",
                      sortable: true,
                        sortDirection: "desc",
                    },
                    {
                        label: "البريد الإلكتروني",
                        key: "email",
                        sortable: true,
                          sortDirection: "desc",
                      },
                      {
                        label: "النوع",
                        key: "type",
                        sortable: true,
                          sortDirection: "desc",
                      },
                    { key: "actions", label: "إعدادات" },
                  ],

                  items: state['users'],
            }
        }
    },
}