/* eslint-disable no-unused-vars */
import store from "@/core/services/store";
import ApiService from "@/core/services/api.service";

export default {
    namespaced: true,
    state: {
        transactions: [],
    },
    mutations: {
        SET_TRANSACTIONS(state, transactions) {
            state.transactions = transactions;
        },
        LOAD_PAGES(state, data){
            if(state.transactions.findIndex(x=>x.id === data[0].id)===-1){
                state.transactions = state.transactions.concat(data)
            }
        }
    },
    actions: {
        async loadAll({ commit }) {
            let response = await ApiService.get('api/v1/driver/transactions/all');
            //console.log(response)
            let transactions = response.data.data.data;            
            commit('SET_TRANSACTIONS', transactions);
            return response;
        },
        async loadPage({ commit},pageNumber ) {
            let response = await ApiService.get(`api/v1/driver/transactions`,`all?page=${pageNumber}`);
            var data = response.data.data.data
            commit('LOAD_PAGES', data);
            
            return response
        },

        async loadPageFiltered({ commit},pageNumber ) {
          let response = await ApiService.get(`api/v1/driver/transactions/filter`,`?page=${pageNumber}`);
          var data = response.data.data
          commit('LOAD_PAGES', data);
          
          return response
      },
        async search({ commit },data) {
          //console.log(data.time[0])
          if(data.time[0] !== null){
            let response = await ApiService.get('api/v1',`driver/transactions/filter?from=${data.time[0]}&to=${data.time[1]}&did=${data.driver_id}`);
          let transactions = response.data;            
          commit('SET_TRANSACTIONS', transactions);
          return response;
          }
          else{
            let response = await ApiService.get('api/v1/driver/transactions/all');
            //console.log(response)
            let transactions = response.data.data.data;            
            commit('SET_TRANSACTIONS', transactions);
            return response;

          }
          
          
      },
        async Approval({commit},approval){
            /*let response = await ApiService.put(`api/v1/driver/transactions/${approval.transaction_id}/update-status`,approval.status)

            return response*/

            const requestOptions = {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' ,
                "Authorization" : 'Bearer '+ localStorage.getItem('token'),},
                body: JSON.stringify({ status: approval.status })
            };
            fetch(`https://apidev.vaiila.com/api/v1/driver/transactions/${approval.transaction_id}/update-status`, requestOptions)
                .then(response => response.json())
                
        }
    },
    getters: {
        table: (state) => {
            return {
                fields: [
                    {
                        key: "id",
                        label: "ID",
                        sortable: true,
                        sortDirection: "desc",
                      },
                      {
                        key: "driver.full_name",
                        label: "Driver",
                        sortable: true,
                        sortDirection: "desc",
                      },
                      {
                        key: "type",
                        label: "Type",
                        sortable: true,
                        sortDirection: "desc",
                      },
                      {
                        key: "amount",
                        label: "Amount",
                        sortable: true,
                        sortDirection: "desc",
                      },
                      {
                        key: "description",
                        label: "Description",
                        sortable: true,
                        sortDirection: "desc",
                      },
                      {
                        key: "date",
                        label: "Date",
                        
                        sortDirection: "desc",
                      },
                      {
                        key: "order_id",
                        label: "Order ID",
                        
                        sortDirection: "desc",
                      },
                      {
                        key: "created_by",
                        label: "Created By",
                        
                        sortDirection: "desc",
                      },
                      {
                        key: "balance_after",
                        label: "Balance After",
                        
                        sortDirection: "desc",
                      },
                      {
                        key: "status",
                        label: "Status",
                        
                        sortDirection: "desc",
                      },
                      
                      
                ],

                items: state['transactions'],
            }
        },
        tableAR: (state) => {
            return {
                fields: [
                  {
                    key: "id",
                    label: "المعرف",
                    sortable: true,
                    sortDirection: "desc",
                  },
                  {
                    key: "driver.full_name",
                    label: "السائع",
                    sortable: true,
                    sortDirection: "desc",
                  },
                  {
                    key: "type",
                    label: "نوع الحركة",
                    sortable: true,
                    sortDirection: "desc",
                  },
                  {
                    key: "amount",
                    label: "المبلغ",
                    sortable: true,
                    sortDirection: "desc",
                  },
                  {
                    key: "description",
                    label: "الوصف",
                    sortable: true,
                    sortDirection: "desc",
                  },
                  {
                    key: "date",
                    label: "التاريخ",
                    
                    sortDirection: "desc",
                  },
                  {
                    key: "order_id",
                    label: "رقم الطلب",
                    
                    sortDirection: "desc",
                  },
                  {
                    key: "created_by",
                    label: "أنشئت بواسطة",
                    
                    sortDirection: "desc",
                  },
                  {
                    key: "balance_after",
                    label: "الرصيد بعد",
                    
                    sortDirection: "desc",
                  },
                  {
                    key: "status",
                    label: "الحالة",
                    
                    sortDirection: "desc",
                  },
                      
                  
                ],

                items: state['transactions'],
            }
        }
    },
}