/* eslint-disable no-unused-vars */
import store from "@/core/services/store";
import ApiService from "@/core/services/api.service";

export default {
    namespaced: true,
    state: {
        clients: [],
    },
    mutations: {
        SET_CLIENTS(state, clients) {
            state.clients = clients;
        },
        ADD_CLIENT(state, client) {
            let clients = state.clients.concat(client);
            state.clients = clients;
        },
        DELETE_CLIENT(state, clientId) {
            let clients = state.clients.filter(c => c.id != clientId);
            state.clients = clients;
        },
        UPDATE_CLIENT(state, client) {
            let updatedclient = state.clients.find(c => c.id == client.id);
            updatedclient = client;
        },
        LOAD_PAGES(state, data){
            if(state.clients.findIndex(x=>x.id === data[0].id)===-1){
                state.clients = state.clients.concat(data)
            }
        }
    },
    actions: {
        async loadAll({ commit }) {
            let response = await ApiService.get('api/v1/clients');
            let clients = response.data.data;            
            commit('SET_CLIENTS', clients);
            return response;
        },
        async search({ commit },value) {
            let response = await ApiService.get('api/v1',`clients?filter=${value}`);
            let clients = response.data.data;            
            commit('SET_CLIENTS', clients);
            return response;
        },
        async loadPage({ commit},pageNumber ) {
            let response = await ApiService.get(`api/v1`,`clients?page=${pageNumber}`);
            var data = response.data.data
            commit('LOAD_PAGES', data);
            return response
        },
        async create({ commit }, client) {
            let response = await ApiService.post('api/v1/clients', client);
            let savedClient = response.data;
            commit('ADD_CLIENT', savedClient);
            return savedClient;
        },
        async delete({ commit }, client) {
            let response = await ApiService.delete(`api/v1/clients/${client.id}`);
            if (response.status == 200 || response.status == 204) {
                commit('DELETE_CLIENT', client.id);
            }
        },
        async update({ commit }, client) {
            let response = await ApiService.update("api/v1/clients", client.id, client);
            let newClient = response.data;
            commit('UPDATE_CLIENT', newClient);
            return newClient;
        },
        async getZones({ commit }) {
            let response = await ApiService.get("api/v1/clients/getZones");
            let zones = response.data;
            return zones;
        },
        async getClientZone({ commit },client) {
            //console.log('-----')
            //console.log(client)
            
            let response = await ApiService.get(`api/v1/clients/${client.id}/zones`);
            let Clientzones = response.data;
            return Clientzones;
        },
        async deleteClintZone({ commit },client_zone_id){
            //console.log(client_zone_id)
            let response = await ApiService.post(`api/v1/clients/zones/${client_zone_id}/delete`);
            /*let response = await ApiService.get(`api/v1/clients/${client.id}/zones`);
            let Clientzones = response.data;
            return Clientzones;*/        
            return response    
        },
        /*async getClientZoneOrder({ commit },client) {
            //console.log('-----')
            //console.log(client)
            var c = client.id + 1
            let response = await ApiService.get(`api/v1/clients/${c}/zones`);
            let Clientzones = response.data;
            return Clientzones;
        },
        async getClientZoneDriver({ commit },client) {
            //console.log('-----')
            console.log(client)
            let response = await ApiService.get(`api/v1/clients/${client.id}/zones`);
            let Clientzones = response.data;
            return Clientzones;
        },*/

        async dashboard({ commit },user) {
            /*//console.log(client[0].id)
            //console.log(user)
            
            var cl = await store.dispatch('clients/loadAll')
            if(cl.data.next_page_url !==null){
                for(var s=1; s<=cl.data.last_page ; s++){
                    store.dispatch('clients/loadPage', s)
                }
            }
            //var cl = this.state.clients.clients
            //var cle = cl.data.data.filter(x=> x.name === user.name)
            var cle2 = this.state.clients.clients.filter(s=> s.name ===user.name)
            //console.log(cle2)*/

            
            //let response = await ApiService.get(`api/v1/client/dashboard/${cle2[0].id}`);
            let response = await ApiService.get(`api/v1/client/dashboard/${user.id}`);
            
            return response;
        },
        async chart({ commit },id) {
            let response = await ApiService.get(`api/v1/orders/dashboard/client/${id}`);
            return response;
        },
    },
    getters: {
        table: (state) => {
            return {
                fields: [
                    {
                        key: "id",
                        label: "ID",
                        sortable: true,
                        sortDirection: "desc",
                      },
                    {
                      key: "name",
                      label: "Name",
                      sortable: true,
                      sortDirection: "desc",
                    },
                    {
                        key: "phone",
                        label: "Phone",
                        sortable: true,
                        sortDirection: "desc",
                      },
                      {
                        key: "email",
                        label: "Email",
                        sortable: true,
                        sortDirection: "desc",
                      },
                      {
                        key: "city",
                        label: "City",
                        sortable: true,
                        sortDirection: "desc",
                      },
                    
                    
                    { key: "actions", label: "Actions" },
                  ],

                items: state['clients'],
            }
        },
        tableAR: (state) => {
            return {
                fields: [
                    {
                        key: "id",
                        label: "المعرف",
                        sortable: true,
                        sortDirection: "desc",
                      },
                    {
                      key: "name",
                      label: "الإسم",
                      sortable: true,
                      sortDirection: "desc",
                    },
                    {
                        key: "phone",
                        label: "رقم الجوال",
                        sortable: true,
                        sortDirection: "desc",
                      },
                      {
                        key: "email",
                        label: "الإيميل",
                        sortable: true,
                        sortDirection: "desc",
                      },
                      {
                        key: "city",
                        label: "المدينة",
                        sortable: true,
                        sortDirection: "desc",
                      },
                    
                    
                    { key: "actions", label: "إعدادات" },
                  ],

                items: state['clients'],
            }
        }
    },
}