/* eslint-disable no-unused-vars */
import store from "@/core/services/store";
import ApiService from "@/core/services/api.service";

export default {
    namespaced: true,
    state: {
        tarrifs: [],
    },
    mutations: {
        SET_TARRIFS(state, tarrif) {
            state.tarrifs = tarrif;
        },
        ADD_TARRIF(state, tarrif) {
            let tref = state.tarrifs.concat(tarrif);
            state.tarrifs = tref;
        },
        DELETE_TARRIF(state, tarrifID) {
            let tarrif = state.tarrifs.filter(d => d.id != tarrifID);
            state.tarrifs = tarrif;
        },
        UPDATE_TARRIF(state, tarrif) {
            let updatedTarrif = state.tarrifs.find(d => d.id == tarrif.id);
            updatedTarrif = tarrif;
        },
        LOAD_PAGES(state, data){
            if(state.dschedule.findIndex(x=>x.id === data[0].id)===-1){
                state.dschedule = state.dschedule.concat(data)
            }
        }
    },
    actions: {
        async loadAll({ commit }) {
            //console.log(this.state)
            let response = await ApiService.get('api/v1/tarrifs');
            //console.log(response.data.data.data)
            //console.log(response)
            let tarrifs = response.data;
            commit('SET_TARRIFS', tarrifs);
            //console.log(driverSchedules)
            return response;
            
        },
        async loadPage({ commit},pageNumber ) {
            let response = await ApiService.get(`api/v1/schedule/drivar`,`show?page=${pageNumber}`);
            var data = response.data.data
            commit('LOAD_PAGES', data.data);
            
            return response
        },
        async create({ commit }, tarrif) {
            let response = await ApiService.post('api/v1/tarrifs', tarrif);
            let savedTarrif = response.data;
            commit('ADD_TARRIF', tarrif);
            return savedTarrif;
        },
        async delete({ commit }, tarrif) {
            //console.log(tarrif)
            let response = await ApiService.delete(`api/v1/tarrifs/${tarrif.id}`);
            if (response.status == 200 || response.status == 204) {
                commit('DELETE_TARRIF', tarrif.id);
            }
        },
        async update({ commit }, tarrif) {
            let response = await ApiService.update("api/v1/tarrifs", tarrif.id, tarrif);
            let newTarrif = response.data;
            commit('UPDATE_TARRIF', newTarrif);
            return newTarrif;
        },
    },
    getters: {
        table: (state) => {
            return {
                fields: [
                    {
                        key: "id",
                        label: "ID",
                        sortable: true,
                        sortDirection: "desc",
                    },
                    {
                        key: "tarrif_name",
                        label: "Name",
                        sortable: true,
                        //class: "text-center",
                    },
                    {
                        key: "zones",
                        label: "Zones",
                        sortable: true,
                        //class: "text-center",
                    },
                  
                    { key: "actions", label: "Actions", sortable: false },
                ],

                items: state['tarrifs'],
            }
        },
        tableAR: (state) => {
            return {
                fields: [
                    {
                        key: "id",
                        label: "المعرف",
                        sortable: true,
                        sortDirection: "desc",
                    },
                    {
                        key: "tarrif_name",
                        label: "الإسم",
                        sortable: true,
                        //class: "text-center",
                    },
                    {
                        key: "zones",
                        label: "المناطق",
                        sortable: true,
                        //class: "text-center",
                    },
                  
                    { key: "actions", label: "الإعدادات", sortable: false },
                ],

                items: state['tarrifs'],
            }
        }
    },
}